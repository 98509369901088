<template>
  <div>
    <b-row v-for="observation in observations" :key="observation.id">
      <b-col sm="8">
        <ResizeAuto>
          <template v-slot:default="{ resize }">
            <textarea
              @input="resize"
              @focus="resize"
              v-model.trim="observation.observation"
            ></textarea>
          </template>
        </ResizeAuto>
      </b-col>
      <b-col>{{ observation.creation_date | FormatToDateTime }}</b-col>
      <b-col style="max-width: 70px">
        <button-delete
          @click="deleteObservation(observation.id)"
        ></button-delete>
      </b-col>
    </b-row>
    <div v-if="observations.length == 0">
      <p>No tiene comentarios.</p>
    </div>
    <div class="row mt-1">
      <div class="col" style="text-align: left">
        <b-button class="mr-1" size="sm" @click="addObservation"
          >Agregar Comentario</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";
import ResizeAuto from "@/components/reusable/ResizeAuto";

export default {
  name: "StudyUnitObservations",
  components: {
    ResizeAuto,
  },
  props: {
    session_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      observations: [],
    };
  },
  methods: {
    save() {
      this.observations.forEach((element) => {
        if (isNaN(element.id)) {
          this.createObservation(element);
        } else {
          this.updateObservation(element);
        }
      });
      this.$emit("saved");
    },
    fetchObservations() {
      if (isNaN(this.session_id)) return;
      this.$restful
        .Get(
          `/teaching/session-planning-observation/?session=${this.session_id}`
        )
        .then((response) => {
          this.observations = response;
        });
    },
    createObservation(comment) {
      if (isNaN(comment.session)) return;
      this.$restful
        .Post("/teaching/session-planning-observation/", comment)
        .then((response) => {
          const index = this.observations.findIndex((x) => x.id == comment.id);
          if (index != -1) this.observations.splice(index, 1, response);
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              title: "Observación guardada.",
            })
            .fire();
        });
    },
    updateObservation(comment) {
      if (isNaN(comment.session)) return;
      this.$restful
        .Put(`/teaching/session-planning-observation/${comment.id}/`, comment)
        .then((response) => {
          const index = this.observations.findIndex((x) => x.id == comment.id);
          if (index != -1) this.observations.splice(index, 1, response);
          this.$swal
            .mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              title: "Observación modificada.",
            })
            .fire();
        });
    },
    addObservation() {
      let comment = {
        id: generateUniqueId(),
        observation: "",
        session: this.session_id,
      };
      this.observations.push(comment);
    },

    deleteObservation(id) {
      if (!isNaN(id)) {
        this.$restful
          .Delete(`/teaching/session-planning-observation/${id}/`)
          .then(() => {
            let index = this.observations.findIndex((x) => x.id == id);
            if (index != -1) this.observations.splice(index, 1);
          });
      } else {
        let index = this.observations.findIndex((x) => x.id == id);
        if (index != -1) this.observations.splice(index, 1);
      }
    },
  },
  computed: {
    ...mapGetters({
      studyUnit: names.STUDY_UNIT,
    }),
  },
  watch: {},
  created() {
    this.fetchObservations();
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
}
</style>